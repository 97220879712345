/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Loading application images
const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

// Loading stylesheets
import '../src/stylesheets/application.sass'

// Loading Rails UJS and Turboliinks
import Rails from 'rails-ujs';
import Turbolinks from 'turbolinks';
Rails.start();
Turbolinks.start();

// Loading Bootstrap JS
// import 'bootstrap/dist/js/bootstrap';

// console.log('Hello World from Webpacker')
